.header {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  background: #10384f;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14);

  &_logo {
    display: flex;
    padding: 0rem 1rem;
    justify-content: flex-start;
    align-items: center;
    gap: 1.25rem;
  }

  &_home {
    right: 0%;
    margin-left: auto;
    position: absolute;

    &_items {
      margin: 0 1.125rem;
      display: inline-flex;
      align-items: center;
      flex-direction: row;
      padding: 1rem 0 0.5rem;
    }

    &_text {
      margin: 0;
      padding: 3px 0 5px 2px;
      font-weight: 500;
      line-height: 1rem;
      font-style: normal;
      font-family: Roboto;
      font-size: 0.875rem;
      letter-spacing: 0.07813rem;
      text-transform: uppercase;
      color: var(--on-colors-on-primary-active-high, #fff);
    }
  }
}

.notify_window {
  position: fixed;
  background-color: white;
  width: auto;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  margin: 0;
  z-index: 999;
  transition: all 0.3s;
}
.btn_header {
  background-color: #10384f;
  border: none;
}
.bayerheader_logo {
  width: 36px;
}
.groupheader_logo {
  width: 40px;
  margin-top: 5px;
}

.user_icon {
  width: 24px;
  cursor: pointer;
}
.user_profile {
  position: relative;
}
.user {
  top: 40px;
  right: 20px;
  width: 200px;
  padding: 5px;
  height: auto;
  display: none;
  position: absolute;
  align-self: stretch;
  z-index: 1000000000000;
  background-color: white;
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
    0px 6px 30px 5px rgba(0, 0, 0, 0.12), 0px 16px 24px 2px rgba(0, 0, 0, 0.14);

  ul {
    margin: 0;
    padding: 0 7px;
    list-style: none;
  }
  &_list {
    width: 100%;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    padding-bottom: 10px;
    gap: 0.5rem;
    list-style-position: outside;
    &_inside {
      list-style-position: inside;
      font-size: small;
      display: flex;
      align-items: center;
      list-style-type: none;
      gap: 0.5rem;
      padding-left: 30px;
      padding-bottom: 10px;
    }
    button {
      gap: 7px;
      padding: 0;
      width: 100%;
      display: flex;
      cursor: pointer;
    }
  }
}
.user_profile:hover .user {
  display: block !important;
}

.abtbtn {
  background-color: #10384f;
  border: 0;
  color: #fff;
  display: flex;
  align-items: center;
  font-family: "roboto";
  letter-spacing: "1.25px";
  font-weight: "500";
  gap: 5px;
}
.header_home_items.active {
  border-bottom: 3px solid #fff;
}
@media only screen and (max-width: 450px) {
  .header_logo {
    gap: 0.5rem;
    padding: 0rem 0.25rem;
  }
  .header_home_text {
    font-size: 0.6rem;
  }
  .header_home_items {
    padding: 0.5rem 0.5rem;
  }
  .bayerheader_logo {
    width: 26px;
  }
  .groupheader_logo {
    width: 30px;
    margin-top: 5px;
  }
}
